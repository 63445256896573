import '../styles/partials/phones-number-list.scss';
import '../../../fonts/fonts.css';
import '../../../styles/common/inputs.scss';
import '../../../styles//common/buttons.scss';
import '../../../styles/common/validation.scss';
import '../../../styles/common/forms.scss';
import '../../../styles/common/custom-input.scss';
import '../styles/partials/pagenumberer.scss';
import '../styles/_partials/catalog-filter.scss';
import '../styles/_partials/menu.scss';
import '../styles/_partials/product-view.scss';
import '../styles/_partials/cart.scss';
import '../styles/_partials/bonus-card.scss';
import '../scripts/_partials/zone/styles/zones.scss';
import '../styles/_common/select-custom.scss';
import '../styles/_common/buttons.scss';
import '../styles/general.scss';
import '../styles/views/brands.scss';
import '../styles/views/news.scss';
import '../styles/views/staticpage.scss';
import '../styles/views/product.scss';
import '../styles/views/catalog.scss';
import '../styles/views/checkout.scss';
import '../styles/views/checkoutSuccess.scss';
import '../styles/views/registration.scss';
import '../../../styles/views/feedback.scss';